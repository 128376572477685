import _es6Object from "../../modules/es6.object.define-property";
import _core from "../../modules/_core";
var exports = {};
_es6Object;
var $Object = _core.Object;

exports = function defineProperty(it, key, desc) {
  return $Object.defineProperty(it, key, desc);
};

export default exports;